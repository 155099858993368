import { createTheme } from "@mui/material";

const font = "'Roboto', sans-serif";
const theme = createTheme({
    palette:{
        primary:{
        main: "#1f1f24",
        light: "#393E46"
        },
        secondary:{
        main:"#EEEEEE",
        light:"#F7F7F7",
        },
        text:{
          main:"#EEEEEE"
        },
        otherColor:{
        main:"#2f8eba"
        }
    },
    overrides: {
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#121314",
          },
        },
      },
      typography:{
        fontFamily:font,
        h1:{fontWeight:700},
        h2:{fontWeight:600},
        h3:{fontWeight:500},
        h4:{fontWeight:400},
        h5:{fontWeight:300},
        h6:{fontWeight:200},
        p:{fontWeight:100},
      },
        
});
export default theme;