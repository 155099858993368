
import { signOut } from 'firebase/auth';
import {auth} from './firebase-auth.js';

export  const LogOut = async () => {
    try{
        await signOut(auth);
    }catch(e){
        console.log(e);
    }
}