import { AppBar, Stack, Toolbar, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../StateProvider/StateProvider';


export default function NavBar() {


    const [{ user }] = useStateValue();
    const Navigate = useNavigate();

    const image = "https://scontent.fktm1-2.fna.fbcdn.net/v/t39.30808-6/282193165_1681529892246156_4575226066897483551_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=5yptAmoqZFcAX8gGo-2&tn=darGiJfsBfKJ7YRt&_nc_ht=scontent.fktm1-2.fna&oh=00_AT_5Blv2TG6PgjfcIm2xYh2v-9OmaItlmU4P6ZIE_Ydmfw&oe=62C1EFD2";
    const StyledToolBar = styled(Toolbar)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
    }));


    return (
        <AppBar position='static' sx={{ backgroundColor: "#393E46" }}>
            <StyledToolBar sx={{
                flexDirection: { md: "row", xs: "column" },
                padding: { md: "0px 25px", xs: "10px 0px" }
            }}>
                <Typography variant='h2' sx={{ cursor: "pointer", fontSize: { md: "24px", xs: "20px" } }} onClick={() => {
                    Navigate("/");
                }}>
                    Corona Tracker
                </Typography>
                <Stack direction={"row"} alignItems={"center"} sx={{ gap: { md: "70px", xs: "30px" } }}>
                    <Typography variant='h4' sx={{ cursor: "pointer", fontSize: { md: "20px", xs: "17px" } }} onClick={() => {
                        Navigate("/");
                    }}>
                        Home
                    </Typography>
                    <Typography variant='h4' fontSize={"20px"} sx={{ cursor: "pointer", fontSize: { md: "20px", xs: "17px" } }} onClick={() => {
                        Navigate("/tracker");
                    }}>
                        Tracker
                    </Typography>
                    <Typography variant='h4' sx={{ fontSize: { md: "20px", xs: "17px" }, cursor: "pointer" }} onClick={() => {
                        Navigate("/about");
                    }}>
                        About Us
                    </Typography>
                    {user === null ? <Typography variant='h4' onClick={() => {
                        Navigate("/account")
                    }} sx={{ cursor: "pointer", fontSize: { md: "20px", xs: "17px" } }}>Sign In</Typography> : <Stack direction={"row"} alignItems={"center"} gap={"20px"} onClick={() => {
                        Navigate("/profile");
                    }}>
                        {user.photoURL === null ? <Avatar sx={{ display: { md: "flex", xs: "none" } }} >{user.displayName.toUpperCase().charAt(0)}</Avatar> : <Avatar src={user.photoURL} sx={{ display: { md: "block", xs: "none" } }} />}
                        <Typography variant='h4' sx={{ cursor: "pointer", fontSize: { md: "20px", xs: "17px" } }}>
                            Profile
                        </Typography>
                    </Stack>}
                </Stack>
            </StyledToolBar>
        </AppBar>
    )
}
