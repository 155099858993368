import { Box, Button, InputBase, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { createUserWithEmailAndPassword, updateProfile,signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../Firebase/firebase-auth.js';
import { SignInWithGoogle } from '../Firebase/SignInWithGoogle.js';

export default function SignIn() {

    //REF
    const UserRef = useRef();
    const EmailRef = useRef();
    const PasswordRef = useRef();

    const Navigate = useNavigate();

    //STATE
    const [isRegister, setIsRegister] = useState(false);
    const [error,setError] = useState("");


    //FIREBASE FUNCTIONS
    const registerUser =  () => {
        if(UserRef.current !== null){
          createUserWithEmailAndPassword(auth,EmailRef.current.value,PasswordRef.current.value).then(() => {
            return updateProfile(auth.currentUser,{
              displayName:UserRef.current.value,
            });
           }).then(() => {
            Navigate("/");
           }).catch( (e) => {
                if(e.code == "auth/invalid-email"){
                  setError("Invalid Email");
             } if(e.code == "auth/weak-password"){
               setError("Weak Password");
            }if(e.code == "auth/email-already-in-use"){
                setError("Email alreday in use");
            }else {
              console.log(e.code);
            }
           })
        }else{
          setError("UserName Required");
        }
             
      
        }

        const logIn = async () => {
            try{
                await signInWithEmailAndPassword(auth,EmailRef.current.value,PasswordRef.current.value);
                Navigate("/");
            }catch(e){
                if(e.code === "auth/user-not-found"){
                    setError("User not found");
                }
                if(e.code === "auth/invalid-email"){
                    setError("Invalid Email");
                }if(e.code === "auth/wrong-password"){
                    setError("Wrong Password");
                }else{
                    console.log(e.code);
                }
                
            }
        };

    const SearchWrapper = styled('div')({
        backgroundColor: "white",
        padding: "2px 5px",
        borderRadius: "5px"
    });

    return (
        <Box sx={{ flexDirection: "column", alignItems: "center", display: "flex", justifyContent: "center", marginTop: { md: "100px", xs: "10px" }, gap: { md: "5px", xs: "10px" } }}>
            <Stack sx={{ width: { md: "400px", xs: "300px" }, backgroundColor: "secondary.main", padding: { md: "30px 15px", xs: "15px 10px" }, gap: { md: "30px", xs: "20px" }, borderRadius: "5px" }}>
                <Typography textAlign={"center"} variant={"h2"} sx={{ fontSize: "30px", color: "primary.main" }}>
                    Corona Tracker
                </Typography>
                {
                    isRegister === false ? <>
                        <Stack gap={"5px"}>
                            <Typography variant='h3' fontSize={"16px"} sx={{ color: "primary.main" }}>
                                Email
                            </Typography>
                            <SearchWrapper>
                                <InputBase fullWidth inputRef={EmailRef} type={"email"} />
                            </SearchWrapper>
                        </Stack>
                        <Stack gap={"5px"}>
                            <Typography variant='h3' fontSize={"16px"} sx={{ color: "primary.main" }}>
                                Password
                            </Typography>
                            <SearchWrapper>
                                <InputBase fullWidth inputRef={PasswordRef} type={"password"} />
                            </SearchWrapper>
                        </Stack>
                        {
                            error === null ? <></> :
                                <Typography sx={{ color: "red" }}>
                                    {error}
                                </Typography>
                        }
                        <Button sx={{
                            color: "white",
                            backgroundColor: "primary.main", "&:hover": {
                                backgroundColor: "primary.main",

                            },
                            borderRadius: "5px"
                        }} onClick={() => {
                            logIn();
                           
                        }}>
                            Log in
                        </Button>
                        <Button sx={{
                            color: "white",
                            backgroundColor: "#4285f4", "&:hover": {
                                backgroundColor: "#4285f4",

                            },
                            borderRadius: "5px",
                            display: "flex",
                            padding: "8px 0px",
                            gap: "10px"
                        }} onClick={() => {
                            SignInWithGoogle();
                            Navigate("/");
                        }}>
                            <GoogleIcon sx={{ color: "white" }} />
                            <Typography sx={{ fontSize: "14px" }} variant={"h3"}>
                                Sign Up with Google
                            </Typography>
                        </Button>
                        <Typography sx={{ color: "blue", fontSize: "16px", cursor: "pointer" }} variant={"h4"} onClick={() => {
                            setIsRegister(true);
                        }}>
                            Create an Account ?
                        </Typography>
                    </> : <>
                        <Stack gap={"5px"}>
                            <Typography variant='h3' fontSize={"16px"} sx={{ color: "primary.main" }}>
                                User Name
                            </Typography>
                            <SearchWrapper>
                                <InputBase fullWidth inputRef={UserRef} type={"text"} />
                            </SearchWrapper>
                        </Stack>
                        <Stack gap={"5px"}>
                            <Typography variant='h3' fontSize={"16px"} sx={{ color: "primary.main" }}>
                                Email
                            </Typography>
                            <SearchWrapper>
                                <InputBase fullWidth inputRef={EmailRef} type={"email"} />
                            </SearchWrapper>
                        </Stack>
                        <Stack gap={"5px"}>
                            <Typography variant='h3' fontSize={"16px"} sx={{ color: "primary.main" }}>
                                Password
                            </Typography>
                            <SearchWrapper>
                                <InputBase fullWidth inputRef={PasswordRef} type={"password"} />
                            </SearchWrapper>
                        </Stack>
                        {
                            error === null ? <></> :
                                <Typography sx={{ color: "red" }}>
                                    {error}
                                </Typography>
                        }
                        <Button sx={{
                            color: "white",
                            backgroundColor: "primary.main", "&:hover": {
                                backgroundColor: "primary.main",

                            },
                            borderRadius: "5px"
                        }} onClick={() => {
                            registerUser();

                        }}>
                            Register
                        </Button>
                        <Button sx={{
                            color: "white",
                            backgroundColor: "#4285f4", "&:hover": {
                                backgroundColor: "#4285f4",

                            },
                            borderRadius: "5px",
                            display: "flex",
                            padding: "8px 0px",
                            gap: "10px"
                        }} onClick={() => {
                            SignInWithGoogle();
                            Navigate("/");
                        }}>
                            <GoogleIcon sx={{ color: "white" }} />
                            <Typography sx={{ fontSize: "14px" }} variant={"h3"}>
                                Sign Up with Google
                            </Typography>
                        </Button>
                        <Typography sx={{ color: "blue", fontSize: "16px", cursor: "pointer" }} variant={"h4"} onClick={() => {
                            setIsRegister(false);
                        }}>
                            Have an Account? Log in
                        </Typography></>
                }

            </Stack>
            <Typography sx={{ color: "primary.main", fontSize: "16px", cursor: "pointer" }} variant={"h4"}
                onClick={() => {
                    Navigate("/");
                }}>
                Sign in later
            </Typography>
        </Box>
    )
}
