import { Avatar, Stack, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import { useStateValue } from '../StateProvider/StateProvider';
import { LogOut } from '../Firebase/LogOut.js';
import { useNavigate } from 'react-router-dom';

export default function ProfilePage() {

    const [{ user }] = useStateValue();
    const Navigate = useNavigate();

    useEffect(() => {
        if (user === null) {
            Navigate("/");
        }
    });

    return (
        <Box sx={{ backgroundColor: "#F7F7F7", padding: "30px 5vw" }}>
            {user === null ? <></> : <Stack direction={"row"} gap={"30px"}>
                {user.photoURL === null ? <Avatar sx={{ width: "200px", height: "200px" }}>
                    <Typography variant='h3' sx={{ fontSize: "80px", overflow: "hidden" }}>
                        {user.displayName.toUpperCase().charAt(0)}
                    </Typography>
                </Avatar> : <Avatar sx={{ width: "200px", height: "200px" }} src={user.photoURL} />}
                <Stack sx={{ marginTop: "50px", gap: "10px" }}>
                    <Typography variant='h3' sx={{ fontSize: "40px", }}>{user.displayName}</Typography>
                    <Typography variant='h4' sx={{ fontSize: "25px" }}>{user.email}</Typography>
                    <Button sx={{
                        color: "white",
                        backgroundColor: "primary.main", "&:hover": {
                            backgroundColor: "primary.main",
                        },
                        borderRadius: "5px",
                    }} onClick={() => {
                        LogOut();
                        Navigate("/");
                    }}>
                        Log Out
                    </Button>
                </Stack>
            </Stack>}
        </Box>
    )
}
