import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react'

export default function Country({name,img,cases,deaths,recover,tcases,tdeaths,trecover}) {
    const Navigate = useNavigate();
  return (
    <Stack sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center", gap:{md:"20px",xs:"10px"}}}>
                    <Stack sx={{ flexDirection: "row", gap:{md:"15px" ,xs:"10px"}, alignItems: "center" }}>
                        <img src={img} width={"55px"} style={{ objectFit: "contain" }} height={"50px"} />
                        <Typography variant='h3' sx={{ fontSize: {md:"30px",xs:"20px"}, overflow: "hidden",cursor:"pointer" }} onClick={()=>{
                            Navigate("/"+name);
                        }}>
                            {name}
                        </Typography>
                    </Stack>
                    <Stack sx={{width:"100%",backgroundColor:"white",borderRadius:"10px",  border: '1px solid #EEEEEE',padding:"5px 0px"}}>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between",margin:"2px 2vw" }}>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px"}}}>
                                Total Cases
                            </Typography>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px",color:"red"}}}>
                                Total Deaths
                            </Typography>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px",color:"green"}}}>
                                Total Recovered
                            </Typography>
                        </Stack>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between",margin:"2px 2vw" }}>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px"}}}>
                                {cases}
                            </Typography>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px",color:'red'}}}>
                                {deaths}
                            </Typography>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px",color:"green"}}}>
                                {recover}
                            </Typography>
                        </Stack>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between",margin:"2px 2vw" }}>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px"}}}>
                                New Cases
                            </Typography>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px",color:"red"}}}>
                                New Deaths
                            </Typography>
                            <Typography variant='h4' sx={{fontSize:{md:"25px",xs:"16px",color:"green"}}}>
                                New Recovered
                            </Typography>
                        </Stack>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" ,margin:"2px 2vw"}}>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px"}}}>
                               {tcases}
                            </Typography>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px",color:"red"}}}>
                                {tdeaths}
                            </Typography>
                            <Typography variant='h5' sx={{fontSize:{md:"22px",xs:"16px",color:"green"}}}>
                                {trecover}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
  )
}
