import React, { useState } from 'react';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import './BarChat.css';


export default function BarChart({rawData,title,color}) {

var case_map = "a";
  if(title === "Case"){
     case_map = rawData.cases;
  }else{
     case_map = rawData.deaths;
  }

  var case_result = [];
  var result = [];
  var keys = Object.keys(case_map);

  keys.forEach(function (key) {
    case_result.push(case_map[key]);
  });
  for (let i = 0; i < case_result.length - 1; i++) {
    result.push(case_result[i + 1] - case_result[i]);
  }


  keys.shift();

  const [data, setData] = useState({
    labels: keys,
    datasets: [
      {
        label: `${"Daily " + title}`,
        data: result,
        backgroundColor: `${color}`
      },


    ]
  });

  return (

      <div className="chart-container">
        <Line data={data} redraw={true}/>
      </div>
  );
}
