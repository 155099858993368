import { Box, InputBase, Stack, Typography, Button, Snackbar } from '@mui/material'
import React, { useRef, useState } from 'react';
import png from '../img/pngwing.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate, Link } from 'react-router-dom';
import { styled } from '@mui/system';
import './TrackerPage.css'
import { addDoc, collection, doc } from 'firebase/firestore';
import { db } from '../Firebase/DBConnection.js';

export default function AboutUs() {

    const SearchWrapper = styled(Box)({
        padding: "0px 4px",
        backgroundColor: "white",
        borderRadius: "4px",
        border: "2px solid #EEEEEE",
    });

    const messageRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const subjectRef = useRef();

    const [open, setOpen] = useState(false);

    const sendMessage = () => {
        const MessageCollection = collection(db,"Messages");
        addDoc(MessageCollection,{
            name:nameRef.current.value,
            email:emailRef.current.value,
            subject:subjectRef.current.value,
            message:messageRef.current.value
        }).then(()=>{
            setOpen(true);
            nameRef.current.value = "";
            emailRef.current.value = "";
            subjectRef.current.value = "";
            messageRef.current.value = "";
        }).catch((e)=>{
            console.log(e);
        })
    }



    return (
        <>
            <Box sx={{ backgroundColor: "#F7F7F7", padding: "10px 5vw" }}>
                <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Stack sx={{ flexDirection: "column", justifyContent: "space-between", marginTop: { md: "200px", xs: "0px" } }}>
                        <Typography variant='h1' sx={{ overflow: "hidden", fontSize: { md: "130px", xs: "35px" }, color: "primary.main" }}>
                            ABOUT US
                        </Typography>
                        <Typography variant='h4' sx={{ overflow: "hidden", fontSize: { md: "18px", xs: "16px" }, color: "primary.main" }}>
                            Corona Tracker is a website design give us live count and information about corona virus.
                            Coronavirus disease (COVID-19) is an infectious disease caused by the SARS-CoV-2 virus.
                            Most people who fall sick with COVID-19 will experience mild to moderate symptoms and recover without special treatment. However, some will become seriously ill and require medical attention.
                            PLEASE STAY HOME STAY SAFE.
                        </Typography>
                        <Stack direction={"row"} gap={"10px"}>
                            <a href={"https://www.linkedin.com/in/prajwol-neupane-b64418208/"} target="_"><LinkedInIcon sx={{ color: "otherColor.main", fontSize: { md: "35px", xs: "30px" } }} /></a>
                            <a href={"https://www.facebook.com/prajwolxhettry/"} target="_"> <FacebookIcon sx={{ color: "otherColor.main", fontSize: { md: "35px", xs: "30px" } }} /></a>
                            <a href={"https://www.instagram.com/prajwolxhettry/?hl=en"} target="_"><InstagramIcon sx={{ color: "otherColor.main", fontSize: { md: "35px", xs: "30px" } }} /></a>
                        </Stack>
                    </Stack>
                    <img src={png} width={"220%"} className={"img"} />
                </Stack>
            </Box>
            <Box sx={{ backgroundColor: "#F7F7F7", padding: "10px 5vw" }}>
                <Stack sx={{ flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                    <Typography variant='h3' sx={{ fontSize: { md: "40px", xs: "25px" }, color: "primary.main" }}>
                        Message Me
                    </Typography>
                    <SearchWrapper sx={{ width: { md: "40%", xs: "95%" } }}>
                        <InputBase type='text' placeholder='User Name' fullWidth inputRef={nameRef}/>
                    </SearchWrapper>
                    <SearchWrapper sx={{ width: { md: "40%", xs: "95%" } }}>
                        <InputBase type='email' placeholder='User Email' fullWidth inputRef={emailRef}/>
                    </SearchWrapper>
                    <SearchWrapper sx={{ width: { md: "40%", xs: "95%" } }}>
                        <InputBase type='text' placeholder='Subject' fullWidth inputRef={subjectRef}/>
                    </SearchWrapper>
                    <textarea placeholder='Message' className="area" ref={messageRef}/>
                    <Button sx={{
                        color: "white",
                        backgroundColor: "otherColor.main", "&:hover": {
                            backgroundColor: "otherColor.main",

                        },
                        borderRadius: "5px"
                    }} onClick={() => {
                        sendMessage();
                    }}>
                        Send Message
                    </Button>
                    <Snackbar open={open}
                        autoHideDuration={1500}
                        onClose={() => {
                            setOpen(false);
                        }}
                        message="Message Send" />
                </Stack>
            </Box>
        </>
    )
}
