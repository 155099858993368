import Footer from './Components/Footer';
import NavBar from './Components/NavBar';
import SignIn from './Components/SignIn';
import HomePage from './Page/HomePage';
import { Routes ,Route} from 'react-router-dom';
import {auth} from './Firebase/firebase-auth.js';
import React,{useEffect, useState} from 'react';
import { useStateValue } from './StateProvider/StateProvider';
import {onAuthStateChanged} from 'firebase/auth';
import TrackerPage from './Page/TrackerPage';
import CountryPage from './Page/CountryPage';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AboutUs from './Page/AboutUs';
import ProfilePage from './Page/ProfilePage';
import { db } from './Firebase/DBConnection.js';
import { collection, orderBy, query, onSnapshot } from 'firebase/firestore';

function App() {


  const [{user},dispatch] = useStateValue();
  const [data,setData] = useState();

  useEffect(() => {
    onAuthStateChanged(auth,authuser => {
      if(user){
        dispatch({
          type:"SET_USER",
          user:authuser
        });
      }else{
        dispatch({
          type:"SET_USER",
          user:authuser
        });
      }


    }) ;

  }, []);

  return (
    <>
    <Routes>
      <Route path='/' element={<>
      <NavBar />
      <HomePage />
      <Footer />
      </>}/>
      <Route path='/tracker' element={<>
      <NavBar />
      <TrackerPage />
      <Footer />
      </>}/>
      <Route path='/about' element={<>
      <NavBar />
      <AboutUs />
      <Footer />
      </>}/>
      <Route path='/profile' element={<>
      <NavBar />
      <ProfilePage />
      <Footer />
      </>}/>
      <Route path='/:name' element={<>
      <NavBar />
      <CountryPage />
      <Footer />
      </>}/>
      <Route path='/account' element={<SignIn />}/>
    </Routes>
    </>
  );
}

export default App;
