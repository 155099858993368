import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC_ybMQEXG7VGwofC6mkGWV-T2V7qEZaB4",
  authDomain: "corona-tracker-77ae1.firebaseapp.com",
  projectId: "corona-tracker-77ae1",
  storageBucket: "corona-tracker-77ae1.appspot.com",
  messagingSenderId: "114880347310",
  appId: "1:114880347310:web:d5016f0b3e8f1371dd813f"
};


export const app = initializeApp(firebaseConfig);