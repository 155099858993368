import { ThemeProvider } from '@mui/system';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './theme';
import { StateProvider } from './StateProvider/StateProvider.js';
import reducer, { initialState} from './StateProvider/reducer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <StateProvider initialState={initialState} reducer={reducer}>
            <App />
         </StateProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
