import { Box, Stack, Typography } from '@mui/material'
import React,{useState,useEffect} from 'react';
import glob from '../img/globle.png';
import axios from 'axios';
import moment from 'moment';
import '../Page/HomePage.css';

export default function WordTracker() {

    const [data,setData] = useState();
    

    useEffect(() => {
        
        axios.get("https://disease.sh/v3/covid-19/all?yesterday=true&twoDaysAgo=fasle&allowNull=true")
        .then((res) => {
            setData(res.data);
            
        }).catch((e) => {
            console.log(e);
        })

    },[] );



  return (
    <Box sx={{ backgroundColor: "#eeeeee", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", padding: {md:"30px 0px",xs:"15px 0px"}, flexDirection: "column", gap: {md:"50px",xs:"20px"} }}>
        <Typography variant='h2' sx={{fontSize:{md:"60px",xs:"30px"}}}>
            Global
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{width:"90%"}} alignItems={"center"}>
            <Stack direction={"column"} gap={"5px"}>
            <Typography variant='h2'  sx={{color:"black",fontSize:{md:"22px",xs:"16px"}}} >
                    {"Updated on : " +moment(data?.updated).format('dddd, MMMM Do, YYYY h:mm:ss A')}
                </Typography>
                <Typography variant='h3'  sx={{color:"black",fontSize:{md:"22px",xs:"16px"}}}>
                    {"Total Cases : "+data?.cases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3' sx={{color:"red",fontSize:{md:"22px",xs:"16px"}}}>
                {"Total Deaths : "+data?.deaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3'sx={{color:"green",fontSize:{md:"22px",xs:"16px"},overflow:"hidden"}}>
                {"Total Recover : "+data?.recovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3' sx={{color:"black",fontSize:{md:"22px",xs:"16px"}}}>
                {"New Cases : "+data?.todayCases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3'  sx={{color:"red",fontSize:{md:"22px",xs:"16px"}}}>
                {"New Deaths : "+data?.todayDeaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3' sx={{color:"green",fontSize:{md:"22px",xs:"16px"}}}>
                {"New Recovered : "+data?.todayRecovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3'  sx={{color:"black",fontSize:{md:"22px",xs:"16px"}}}>
                {"Total Active Case : "+data?.active.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
                <Typography variant='h3' sx={{color:"red",fontSize:{md:"22px",xs:"16px"}}}>
                {"Total Critical Case : "+data?.critical.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
            </Stack>
            <img src={glob} width={"50%"} alt={"World Map"} className="img"/>
        </Stack>
    </Box>
  )
}
