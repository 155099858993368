import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import Country from '../Components/Country';
import axios from 'axios';
import './TrackerPage.css'
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";

export default function TrackerPage() {

    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);

    const [Search, setSearch] = useState("");


    useEffect(() => {
        axios.get("https://disease.sh/v3/covid-19/countries?yesterday=true&twoDaysAgo=false&sort=true&allowNull=true")
            .then((res) => {
                setData(res.data.sort(function (a, b) {
                    return b.cases - a.cases;
                }));
                setLoad(false);

            }).catch((e) => {
                console.log(e);
            })
    }, []);

    return (
        <div className="cover-box">
            <div className="inner-box">
                <Stack alignItems={"flex-start"}>
                    <Typography variant='h2' sx={{ fontSize: { md: "40px", xs: "25px" }, color: "primary.main" }}>Tracker</Typography>
                    <Typography variant='h4' sx={{ fontSize: { md: "20px", xs: "16px" }, color: "primary.main" }}>  {"Updated on : " + moment(data?.updated).format('dddd, MMMM Do, YYYY h:mm:ss A')}</Typography>
                </Stack>
                <div sx={{ width: { md: "40vw", xs: "90vw" } }} className='search-wrapper'>
                    <input placeholder='Search' style={{ width: "90%", border: "none", outlineWidth: "0", fontSize: "18px" }} className="inputbase" onChange={(e) => {
                        setSearch(e.target.value);
                    }} />
                </div>
            </div>
            {
                load === true ? <div style={{display:"flex",alignItems:"center",justifyContent:"center",padding:"200px 0px"}}> <ClipLoader /></div> : <Stack sx={{ gap: { md: "50px", xs: "25px" }, marginTop: { md: "50px", xs: "25px" } }}>
                    {data.filter((val) => {
                        if (Search == "") {
                            return val;
                        } else if (val.country.toLowerCase().includes(Search.toLowerCase())) {
                            return val;
                        }
                    }).map((curr, indx) => (
                        <Country key={indx} name={curr.country} img={curr.countryInfo.flag}
                            cases={curr.cases === null ? 0 : curr.cases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            deaths={curr.deaths === null ? 0 : curr.deaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            recover={curr.recovered === null ? 0 : curr.recovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            tcases={curr.todayCases === null ? 0 : curr.todayCases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            tdeaths={curr.todayDeaths === null ? 0 : curr.todayDeaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            trecover={curr.todayRecovered === null ? 0 : curr.todayRecovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    ))}
                </Stack>
            }
        </div>
    )
}
