import { Box, Typography, Stack } from '@mui/material'
import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';

export default function Footer() {
    return (
        <Box sx={{ backgroundColor: "#393E46", display: "flex",  alignItems:"center", padding: {md:"70px 0px",xs:"20px 0px"}, flexDirection: "column", gap: "5px" }}>
            <Typography variant='h1'sx={{ color: "white",overflow:"hidden" ,fontSize:{md:"50px",xs:"30px"}}}>
                Corona Tracker
            </Typography>
            <Typography variant='h4'  sx={{ color: "white" ,fontSize:{md:"18px",xs:"14px"}}}>
                &copy; Prajwol Neupane
            </Typography>
            <Stack  sx={{direction:{md:"row",xs:"column",gap:{md:"30px",xs:"10px"}}}} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                <CallIcon sx={{ color: "white" }} />
                <Typography variant='h4' fontSize={"18px"} sx={{color:"white"}}>9865464114</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                <EmailIcon sx={{ color: "white" }} />
                <Typography variant='h4' fontSize={"16px"} sx={{ color: "white" }}>prajwolneupane68@gmail.com</Typography>
            </Stack>
            </Stack>
        </Box>
    )
}
