import { Stack, Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import BarChart from '../Components/BarChart';
import ClipLoader from "react-spinners/ClipLoader";


export default function CountryPage() {

    const params = useParams();
    const [data, setData] = useState();
    const [barData, setBarData] = useState();
    const [load, setLoad] = useState(true);



    useEffect(() => {
        axios.get("https://disease.sh/v3/covid-19/countries/" + params.name + "?yesterday=true&twoDaysAgo=false&strict=true&allowNull=true")
            .then((res) => {
                setData(res.data);

            }).catch((e) => {
                console.log(e);
            })
        axios.get("https://disease.sh/v3/covid-19/historical/" + params.name + "?lastdays=30")
            .then((res) => {
                setBarData(res.data);
                setLoad(false);
            }).catch((e) => {
                console.log(e);
            })
    }, []);

    return (
        <Box sx={{ backgroundColor: "#F7F7F7" }}>
            {
                load === true ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "100px 0px" }}> <ClipLoader /></div> : <>
                    <Stack sx={{ flexDirection: "row", gap: "25px", padding: "20px 5vw" }} >
                        <img src={data?.countryInfo.flag} style={{ objectFit: "cover", width: "20%", height: "10%" }} />
                        <Stack sx={{ justifyContent: "space-between" }}>
                            <Typography variant='h3' sx={{ fontSize: "35px", color: "primary.main", overflow: "hidden" }}>
                                {data?.country}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "20px", color: "primary.main", overflow: "hidden" }}>
                                {"Lat : " + data?.countryInfo.lat + " Long : " + data?.countryInfo.long}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "20px", color: "primary.main", overflow: "hidden" }}>
                                {data?.continent}
                            </Typography>
                            <Typography variant='h3' sx={{ fontSize: "20px", color: "primary.main", overflow: "hidden" }}>
                                {"Updated on : " + moment(data?.updated).format('dddd, MMMM Do, YYYY h:mm:ss A')}
                            </Typography>
                            <Button sx={{
                                color: "white",
                                backgroundColor: "primary.light", "&:hover": {
                                    backgroundColor: "primary.light",

                                },
                                borderRadius: "5px"
                            }} onClick={() => {
                            }}>
                                Add to List
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", padding: "20px 5vw" }}>
                        <Stack sx={{ marginTop: { md: "40px", xs: "10px" } }}>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "primary.main" }}>
                                {data.cases === null ? "Total Cases : " + 0 : "Total Cases : " + data.cases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "red" }}>
                                {data.deaths === null ? "Total Deaths : " + 0 : "Total Deaths : " + data.deaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "green" }}>
                                {data.recovered === null ? "Total Recovered : " + 0 : "Total Recovered : " + data.recovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "primary.main" }}>
                                {data.todayCases === null ? "New Cases: " + 0 : "New Cases: " + data.todayCases.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "red" }}>
                                {data.todayDeaths === null ? "New Deaths: " + 0 : "New Deaths: " + data.todayDeaths.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "green" }}>
                                {data.todayRecovered === null ? "New Recovered: " + 0 : "New Recovered: " + data.todayRecovered.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                        </Stack>
                        <Stack sx={{ marginTop: { md: "40px", xs: "10px" } }}>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "primary.main" }}>
                                {data.active === null ? "Total Active Case : " + 0 : "Total Active Case : " + data.active.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "red" }}>
                                {data.critical === null ? "Total Critical Case : " + 0 : "Total Critical Case : " + data.critical.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "primary.main" }}>
                                {data.cases === null ? "Total Case Rate : " + 0 : "Total Case Rate : " + `${(data.cases * 100 / data.population).toString().substring(0, 5)}` + "%"}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "red" }}>
                                {data.deaths === null ? "Total Death Rate : " + 0 + "%" : "Total Death Rate : " + `${(data.deaths * 100 / data.population).toString().substring(0, 5)}` + "%"}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "green" }}>
                                {data.recovered === null ? "Total Recovered Rate : " + 0 + "%" : "Total Recovered Rate : " + `${(data.recovered * 100 / data.population).toString().substring(0, 5)}` + "%"}
                            </Typography>
                            <Typography variant='h4' sx={{ fontSize: "22px", color: "blue" }}>
                                {data.tests === null ? "Total Test Done: " + 0 : "Total Test Done: " + data.tests.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Typography>
                        </Stack>
                    </Stack>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "20px" }}>
                        <Typography variant='h2' sx={{ fontSize: { md: "35px", xs: "25px" }, color: "primary.main" }}>Last 29 Days Report</Typography>
                        <Typography variant='h3' sx={{ fontSize: "20px", color: "black" }}>New Cases</Typography>
                        <BarChart title={"Case"} rawData={barData?.timeline} color={"black"} />
                        <Typography variant='h3' sx={{ fontSize: "20px", color: "red" }}>New Deaths</Typography>
                        <BarChart title={"Death"} rawData={barData?.timeline} color={"red"} />
                    </div>
                </>
            }

        </Box>
    )
}
