import { Typography, Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import bgImg from '../img/bgImage.png';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WordTracker from '../Components/WordTracker';
import axios from 'axios';
import "./HomePage.css";
import BarChart from '../Components/BarChart';
import ClipLoader from "react-spinners/ClipLoader";

export default function HomePage() {

    const [data, setData] = useState({});
    const [load, setLoad] = useState(false);

    useEffect(() => {

        axios.get("https://disease.sh/v3/covid-19/historical/all?lastdays=30")
            .then((res) => {
                setData(res.data);
                setLoad(true);

            }).catch((e) => {
                console.log(e);
            })

    }, []);
    return (
        <>
            <div style={{ width: "100%", height: "120px", backgroundColor: "rgb(18,19,20,0.01)" }} className="img"></div>
            <img className='img' src={bgImg} width={"100%"} height={"500px"} style={{ objectFit: "cover", backgroundColor: "rgb(18,19,20,0.01)" }} alt={"Doctor"} />
            <Box sx={{ zIndex: 2, position: "absolute", top: "62.5px", backgroundImage: "linear-gradient(rgb(2, 2, 39,0),rgb(18,19,20,0.9))", width: "100%", height: "622px", opacity: "1", alignItems: "center", display: { md: "flex", xs: "none" }, justifyContent: "center", flexDirection: "column" }} className="img">
                <Typography variant='h2' sx={{ color: "white", marginTop: "150px", fontSize: "80px" }}>
                    Not All Heroes Wear Capes
                </Typography>
                <Typography variant='h5' sx={{ color: "white", fontSize: "20px", letterSpacing: "40px" }}>
                    THANK YOU
                </Typography>
            </Box>
            <WordTracker />
            {load == false ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "100px 0px" }}> <ClipLoader /></div> : <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#eeeeee", flexDirection: "column", gap: "30px" }}>
                <Typography variant='h2' sx={{ fontSize: { md: "30px", xs: "18px" } }}>Last Report </Typography>
                <BarChart rawData={data} title={"Case"} color={"black"} />
                <BarChart rawData={data} title={"Death"} color={"red"} />
            </div>}
            <Box sx={{ backgroundColor: "#eeeeee", zIndex: 4, marginTop: { md: "-5px", xs: "0px" }, display: "flex", justifyContent: "center", alignItems: "center", width: "100%", padding: { md: "70px 0px", xs: "10px 0px" }, flexDirection: "column", gap: "20px" }}>
                <Typography variant='h2' sx={{ color: "primary.main", fontSize: { md: "60px", xs: "30px" } }}>
                    Get your vaccine now
                </Typography>
                <Typography sx={{ margin: { md: "0px 10vw", xs: "0px 2vw" }, fontSize: { md: "18px", xs: "15px" } }} textAlign={"center"} variant='h4' fontSize={"15px"}>
                    As at 22 May 2022, almost one billion people in lower-income countries remain unvaccinated. Only 57 countries have vaccinated 70% of their population – almost all of them high-income countries.
                    We must continue to support all countries to reach 70% vaccination coverage as soon as possible, including 100% of those aged over 60; 100% of health workers; and 100% of those with underlying conditions.
                    Vaccine supply has improved, but absorption has not kept pace. In some countries, we see insufficient political commitment to roll out vaccines. This was impacted by the initial lack of political commitment for equitable access to vaccines. In some, we see gaps in operational or financial capacity. And in all, we see vaccine hesitancy driven by misinformation and disinformation.
                </Typography>
                <Stack sx={{ flexDirection: { md: "row", xs: "column" }, gap: { md: "200px", xs: "10px" } }}>
                    <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                        <CallIcon sx={{ color: "#121314" }} />
                        <Typography variant='h4' fontSize={"18px"}>XXXXXXXXXX</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                        <EmailIcon sx={{ color: "#121314" }} />
                        <Typography variant='h4' fontSize={"18px"}>X@gmail.com</Typography>
                    </Stack>
                </Stack>
            </Box>

        </>
    )
}
